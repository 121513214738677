import * as React from "react";
import {
  Wrapper,
  LegalMentionsWrapper,
  RowWrapper,
} from "./GraffitiHistoire.module.css";
import { GrayTitle } from "../../../../css/Title.module.css";
import banner1 from "../../../../res/histoire/post-graff-1.png"
import banner2 from "../../../../res/histoire/post-graff-2.png"
import banner3 from "../../../../res/histoire/post-graff-3.png"

const HistoireGraffiti = () => {
  return (
    <div className={Wrapper}>
      <h1 className={GrayTitle} >
        DU GRAFFITI AU POST-GRAFFITI
      </h1>
      <div  style={{alignItems: "center",textAlign: "center", paddingTop: "10px"}}>
              <img style={{width: "100%"}} src={banner1} alt=""/>
            </div>
      <div className={LegalMentionsWrapper}>
        <div className={RowWrapper}>
          <div>
            <p style={{fontWeight:"bolder"}}>Révolution Artistique</p>
            <p>Synonyme de vandalisme pour les uns, de forme légitime de culture visuelle et d’appropriation revendiquée de l’espace pour les autres, le graffiti new-yorkais des années 70-80 - et ses différents styles - ne laissaient personne indifférent.</p>
            <br/><br/>
            <p style={{fontWeight:"bolder"}}>« Taki 183 Spawns Pen Pals » 21 Juillet 1971, New York Times</p>
            <p>Nous sommes en Juillet 1971. Un article dans le NY Times fait sensation aussi bien sur l’île de Manhattan que dans les quartiers les plus défavorisés de la ville. « Taki 183 Spawns Pen Pals » : pour la première fois, un journal officiel se penche sur le phénomène du writing.</p>
            <br />
            <p>Au cours de cette année 71, le tag est partout dans les rues de New-York. Les graffeurs commencent à peaufiner leur style en suivant un système d’apprentissage extrêmement hiérarchisé qui permettait aux novices d’approcher les pionniers et fondateurs du mouvement, système d’apprentissage qui suivait des règles précises : les maîtres pouvaient aussi bien critiquer les créations des apprentis, leur donner du travail (des tags à recopier dans leurs blackbooks) ou les inviter – si leur parcours initiatique était abouti - à réaliser une oeuvre à 4 mains à petite ou grande échelle. Le jeune graffeur intégrait alors un crew. A lui de faire ses preuves en améliorant : son propre style (et celui du groupe), sa technique, tout en maîtrisant de mieux en mieux la bombe aérosol (et en se familiarisant avec les palettes de couleurs des fabricants d’aérosol); sans oublier l’apprentissage du réseau urbain de la MTA, de l’emplacement des différents dépôts de trains et des chantiers en cours … une vraie quête avec ses codes et ses lois ; ses joies et ses peines ; ses risques et ses montées d’adrénaline, ses rencontres et ses haines. Un monde dans la ville écrit par des enfants et des pré et post-ados. Avec pour but ultime : la reconnaissance de sa « famille » et de ses pairs comme le roi d’une ligne : le «King of style ». Être vu du plus grand nombre pour être vu de tous ; sortir de l’anonymat "anonymement"... une société dans la société…</p>
            <br/><br/>
            <p style={{fontWeight:"bolder"}}>Fondement, affirmation</p>
            <p style={{fontStyle: "italic"}}>"Ces jeunes qui ont trouvé dans le graffiti un exutoire à leur ardent désir d’identité et de reconnaissance, ne sont pas prêt à faire une croix dessus".</p>
            <br />
            <p>Le fondement du graffiti: l’identité</p>
            <p>Le tag: le moyen de l’affirmer librement, authentiquement et visuellement en dehors de toute contrainte.</p>
            <br />
            <p>Entre 1972 et 1973, la recherche de styles, pour se démarquer du nombre croissant de writers, bat son plein. Malgré les premières lois anti-graffiti de 1972, le phénomène s’accentue. La simplicité des signatures du début fait place à un travail plus élaboré, donnant naissant à des styles identifiables et à des maîtres du style.
Croix, étoiles, spirales apparaissent comme motifs de remplissage à l’intérieur des graffs (Phase2 développe sa lettre de type Bubble et excellera dans le Wild Style, un style « sauvage » illisible par les non initiés où s’enchevêtrent les lettres créées en 1974 par Tracy 168. Il ira jusqu'à brouiller la lisibilité de son nom, l’essence même du tag.). Autour des lettres apparaissent les fonds. Les lettres gagnent en volume avec les graffs qui ajoutent un effet 3D. (Ali du groupe Soul Artists). Mais la virtuosité des writers ne se limite pas à l’élaboration des lettrages. Elle investit aussi le terrain de l’illustration assimilant et/ou modifiant des références de la culture de masse (cartoons, comics, cinéma...).</p>
            <br/>
            <p>Les hits (tag, signatures) jusque-là limités aux stations de métro et à l’intérieur des wagons, deviennent plus lisibles et s’approprient l’extérieur des métros. Grâce à l’utilisation du fat cap (embout), les parois des trains se trouvent couvertes de pièces (graff, lettrages épais et contournés) de plus en plus grandes, de plus en plus complexes. Cette cohésion, cette affirmation et ce caractère illégal rendirent les graffeurs fascinants et attrayants aux yeux des sphères artistiques de NYC car leurs qualités laissaient entrevoir l’éclosion d'une nouvelle avant-garde artistique.</p>
            <br />
            <p>Claes Oldenburg, 1973 « Vous êtes là, dans une station, tout est gris et lugubre, et tout à coup, l’une de ces rames colorées et taguées arrive et illumine l’endroit comme un bouquet d’Amérique latine ».</p>
            <br />
            <p>Signe de l’ampleur que prend le writing au début des années 70, certains observateurs culturels commencèrent à s’intéresser à cette nouvelle forme d’art, inventée et créée dans les ghettos, en dehors de l’art conventionnel admis.</p>
            <br />
            <p>Le critique d’art R. Goldstein écrivait en mars 1973 un article dans le New York magazine intitulé the graffiti hit parade «L’essentiel dans le graffiti ce n’est pas sa force de destruction mais de cohésion, sa capacité à réunir toute une génération de gosses issus des classes populaires dans une expérience à la fois positive et délinquante ».</p>
            <br />
            <p>Hugo Martinez, étudiant en sociologie, fonde un collectif appelée UGA, conscient du potentiel artistique de cette première génération d’artistes et regroupe dans un atelier des writers tels que Phase2 ou StayHigh 149. Dès septembre 1973, la Razor gallery dans le quartier de Soho 464 West Broadway. accueille le groupe et ouvre ses portes au grand public. Ce nouveau langage artistique vient de rentrer en galerie... un demi siècle déjà !... Il balaie ainsi les conventions, les règles et les canons artistiques de l’époque avec exubérance et humour… cet art là, on ne se demande pas ce qu’il veut, pourquoi il est comme ça…car il EST, tout simplement, regardant le monde aux travers de fenêtres bien différentes de celles admises jusqu’alors.</p>
            <br />
            <p>Le critique du New York Times, Peter Schjeldahl écrivait sur cette exposition : « Les tableaux manquaient de structure mais présentaient une incroyable force dans l’utilisation des couleurs. Ce travail (à la bombe) sur toile reprenait l’exubérante insolence des tags vus à travers la ville sur les métros et les murs. Les efforts des enfants du ghetto étaient emprunts d’une énergie volcanique indomptable. Ces jeunes qui ont trouvé dans le graffiti un exutoire à leur ardent désir d’identité et de reconnaissance, ne sont pas prêts à faire une croix dessus ».</p>
            <br />
            <div  style={{alignItems: "center",textAlign: "center", paddingTop: "10px"}}>
              <img style={{width: "100%"}} src={banner2} alt=""/>
            </div>
            <br/>
            <p style={{fontWeight:"bolder"}}>« Un nouveau dépôt » : Le nom est la foi du graffiti</p>
            <p style={{fontStyle: "italic"}}>Le monde des galeries, des collectionneurs et des critiques, ne semblait pas différent de celui du graffiti : « il s’agissait d’un nouveau dépôt, d’un nouveau terrain de jeu dans lequel les graffeurs devaient partir de 0 et faire leurs preuves - Dondi White</p>
            <br />
            <p>Tous les artistes du métro n’aspiraient pas à être exposés en galerie. Ceux qui le souhaitaient, ceux qui avaient eu l’opportunité ou le talent d’intégrer le monde de l’art « officiel » prirent des directions créatives différentes mais complémentaires vis à vis de la culture originelle du graffiti.</p>
            <br />
            <p>Il y avait les artistes qui, sur toile, répétèrent leur tags (tels que Seen, Zephyr ou Blade) et ceux qui explorèrent de nouveaux thèmes (tels que Dondi White, Lee Quinones, Futura 2000 ou Daze); il y avait ceux qui partirent exposer en Europe dès la fin des années 70 (galerie la Medusa, Rome, 1979) et ceux qui partirent plus tard.
Les graffeurs de l’époque savaient que le monde de l’art les considérerait avec fascination et suspicion sans être vraiment conscient de ce qu’était la culture graffiti. C’est grâce, en partie, à cette perception de ces artistes, que le passage de la rue à la toile fut bien accepté dès la fin des années 70.</p>
            <p>La plupart des graffeurs étaient afro-américains, portoricains, sud américains ou issus d’un métissage. Le simple fait qu’ils aient été «enfants du ghetto», du Bronx ou de Brooklyn, sous-entendait qu’ils n’étaient pas blancs. Ils offraient donc de nouvelles perspectives à la société américaine en tendant un miroir à la culture hégémonique des critiques, curateurs, conservateurs et autres influenceurs. Mais parce qu’ils n’empruntaient pas à la culture «de l’élite» des thèmes précis, leurs créations ne reçurent que trop peu de crédit. On a voulu très vite les enfermer dans un monde immobile aux contours figés. Mais parce que l’essence même du graffiti était le mouvement, ils se libérèrent de ces contraintes.</p>
            <br />
            <p>Norman Mailer – Lauréat du prix Pulitzer – publia un essai en 1974 à la gloire du graffiti : Faith of graffiti. « C’est comme si le graffiti était arrivé pour envahir le monde; ce mouvement qui à l’origine était un moyen d’expression des populations émigrées évoluant dans un environnement monotone fait d’acier et de briques, de béton et de bruit, éclata pour sauver les murs vides de la ville». Le tag, unique et fondamental, l’essence même du tout style fait dire au gaffeur CAY 161 dans une interview avec Norman Mailer: «le nom est la foi du graffiti ».</p>
            <br />
            <p>En 1980 a lieu l’exposition New York New Wave au centre P.S.1 (Moma) à New York. Des artistes installés tel que Warhol y côtoient des graffiti-artistes ou des peintres inspirés tel que Basquiat. Le studio de Sam Esses, dirigé par Zephyr et Futura fit son apparition durant 2 mois (East 75th Street à Manhattan). « Venez au studio de graffiti et faites ce que vous faites quand vous vous trouvez devant une voiture de métro, mais ici, vous pouvez prendre tout le temps que vous voulez sans le stress et le danger de la cour». Comment ce serait de peindre des graffitis sur de grandes toiles au lieu de métros? Futura y a peint sa toute première toile… comme 99% des participants. « Dans l'environnement du studio, nous avons commencé à nous voir sous un jour différent. La plupart d'entre nous, nous voyions comme des artistes pour la première fois - aussi ringard que cela puisse paraître - nous étions auparavant conditionnés à nous voir comme des vandales et des parias, parce que c'était notre créneau sociétal ».</p>

            <br />
            <div  style={{alignItems: "center",textAlign: "center", paddingTop: "10px"}}>
              <img style={{width: "100%"}} src={banner3} alt=""/>
            </div>
            <br/>
            <p style={{fontWeight:"bolder"}}>Avant garde esthétique, culture urbaine</p>
            <p>Des galeries s’ouvrent consacrées à la promotion d’artistes issus du mouvement graffiti :</p>
            <br />
            <p>- la Fashion Moda (3e avenue, dans le Bronx): en octobre 1980, l’artiste John Matos (aka Crash) est curateur de l’exposition «Graffiti Art Success for America (GAS)», avec des oeuvres d'autres artistes comme Futura, Lady Pink et John Fekner.</p>
            <p>- la Fun Gallery (East Village) de Patti Astor qui représente à l’époque Dondi White, Futura 2000, Basquiat et Keith Haring entre autres.</p>
            <br />
            <p>Daze :<p style={{fontStyle: "italic"}}>«Le graffiti alors était une langue que les critiques voulaient apprendre superficiellement ; ils ne voulaient l’apprendre couramment»</p></p>
            <br />
            <p>On voit la sortie du film Wild Style de Charlie Ahearn qui met en scène Lady Pink et Lee Quinones; du documentaire Style Wars qui présente de nombreux artistes et met en valeur Kase2 et Dondi White.</p>
            <br />
            <p>Hors des expositions, les artistes se rencontrent, se fréquentent, sortent ensemble brisant les frontières entre les pratiques: l’atelier partagé par Lee, Futura et Zephyr dans le quartier de West Spanish était un lieu où se croisaient la photographe Cindy Sherman, l’artiste Barbara Kruger, connue pour ses affiches percutantes et même les jeunes peintres français de la Figuration libre (François Boisrond, Rémi Blanchard, et Hervé Di Rosa). Tout comme l’oeuvre Honey Tell Me (1983) collaboration entre Jenny Holzer et Lady Pink. Ils ouvrent ainsi la voie vers une nouvelle avant-garde artistique et esthétique.</p>
            <br />
            <p>Keith Haring : <p style={{fontStyle: "italic"}}>«Presque immédiatement après mon arrivée à New York en 1978, j’avais commencé à être intéressé, intrigué et fasciné par le graffiti que je voyais dans les rues et dans les métros. Je prenais souvent les trains pour aller dans les musées et les galeries, et je commençais à voir non seulement le grand graffiti à l’extérieur des rames de métro, mais l’incroyable calligraphie à l’intérieur des voitures. Les trucs calligraphiques me rappelaient ce que j’avais appris de la calligraphie chinoise et japonaise. Il y avait également cette espèce de flot de conscience - ce flux esprit-main que j’avais vu chez Dubuffet, Mark Tobey, et Alechinsky ».</p></p>
            <br />
            <p>En décembre 1983, sous la dénomination de Post-graffiti, le galeriste Sidney Janis, célèbre pour ses succès avec les artistes du pop art ouvre ses portes à ces artistes afin qu’ils réalisent légalement des oeuvres à l’aérosol sur toile (A-one, Jean Michel-Basquiat, Crash, Daze, Futura 2000, Keith Haring, Lady Pink, Don Leicht, Noc 167, Lee Quinnones, Ramm-Ell-Zee, Kenny Scharf).
Le titre offre ainsi une nouvelle sémantique et clarifie les choses : même si les toiles peuvent être similaires aux oeuvres réalisées dans la rue, il ne s’agit plus de graffiti writing mais de post-graffiti. Petit à petit, les artistes s’affranchiront des codes pratiques et esthétiques du graffiti writing afin de proposer des oeuvres qui iront au-delà de la simple transposition de ce dernier. Mais les critiques hostiles voire condescendantes incitent certains artistes à traverser l’Atlantique, exposant leurs oeuvres, fiers et honnêtes vis à vis de leur art. Une nouvelle page de l'histoire née...</p>
           <br />
           <p>... et n’en déplaisent à certains!, le graffiti writing puis le Post-graffiti ont été l'une des plus fantastiques révolutions artistiques du siècle dernier, avec ses maîtres et ses génies, le dernier grand mouvement artistique du XX° siècle!.</p>
            <br />
            <p style={{fontSize: "8px"}}>C.PdB</p>
            <p style={{fontSize: "8px"}}>© GHOST galerie - 2018 - TOUS DROITS RÉSERVÉS | MENTIONS LÉGALES</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoireGraffiti;
