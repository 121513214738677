// extracted by mini-css-extract-plugin
export var Bold = "GraffitiHistoire-module--Bold--JH5UM";
export var ContactLink = "GraffitiHistoire-module--ContactLink--WdZDk";
export var Contactlink = "GraffitiHistoire-module--Contactlink--cpgFE";
export var DevelopmentWrapper = "GraffitiHistoire-module--DevelopmentWrapper--SNtx6";
export var LegalMentionsWrapper = "GraffitiHistoire-module--LegalMentionsWrapper--Os63P";
export var Link = "GraffitiHistoire-module--Link--B6366";
export var NameValueWrapper = "GraffitiHistoire-module--NameValueWrapper--gPGoB";
export var RowWrapper = "GraffitiHistoire-module--RowWrapper--g4D9n";
export var Wrapper = "GraffitiHistoire-module--Wrapper--OoQRY";
export var link = "GraffitiHistoire-module--link--48OXJ";